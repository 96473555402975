import { useState } from 'react'

function CarouselState (length) {
  const [slide, setSlide] = useState(0)

  const next = () => {
    setSlide(slide => {
      if (slide < length - 1) {
        return slide + 1
      } else if (slide === length - 1) {
        return 0
      }
    })
  }

  const previous = () => {
    setSlide(slide => {
      if (slide > 0) {
        setSlide(slide - 1)
      } else if (slide === 0) {
        setSlide(length - 1)
      }
    })
  }

  return {
    slide,
    next,
    previous,
    setSlide
  }
}

export default CarouselState
