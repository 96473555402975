import React, { useRef } from 'react'
import { graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { Col, Row } from 'react-styled-flexboxgrid'
import GatsbyImage from 'gatsby-image'
import scrollToComponent from 'react-scroll-to-component'

import Background from '../components/Background'
import Social from '../components/Social'
import References from '../components/References'
import Calendar from '../components/Calendar'
import slugify from '../utils/slugify'
import HouseHeader from '../components/HouseHeader'

import useCalendarLimit from '../hooks/useCalendarLimit'
import { colors, device } from '../styles/config'

const Container = styled.div`
  ${ props => props.theme.wrapper }

  h4 {
    &:before {
      display: block;
      content: '';
      margin-bottom: 20px;
      width: 55px;
      height: 5px;
      background-color: ${ colors.highlight };

      @media ${ device.sm } {
        margin-bottom: 10px;
      }
    }
  }
`

const RoomsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const RoomImage = styled(GatsbyImage)`
  filter: saturate(50%) contrast(70%);

  img {
    transition: opacity 0.5s, transform 0.5s !important;
  }
`

const RoomImageWrapper = styled.div`
  position: relative;
`

const RoomImageGradient = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1;
  opacity: 0.3;
  visibility: visible;
  transition: opacity 0.5s;

  ${ props => {
    switch (props.name) {
    case 'wiese':
      return css`background-image: linear-gradient(212deg, #43A047 0%, #378577 100%);`
    case 'sonnendeck':
      return css`background-image: linear-gradient(231deg, #C89A21 0%, #D16B18 100%);`
    case 'brueckendeck':
      return css`background-image: linear-gradient(234deg, #EB6B64 34%, #EB6495 100%);`
    case 'theaterdeck':
      return css`background-image: linear-gradient(45deg, #86C6E5 0%, #4B86F0 52%);`
    }
  } }
`

const RoomLink = styled(Link)`
  display: block;
  width: 50%;
  margin-bottom: 1.875rem;
  color: ${ colors.primary };

  @media ${ device.sm } {
    width: 100%;
  }

  &:nth-of-type(1),
  &:nth-of-type(3) {
    padding-right: 0.9375rem;

    @media ${ device.sm } {
      padding: 0;
    }
  }

  &:nth-of-type(2),
  &:nth-of-type(4) {
    padding-left: 0.9375rem;

    @media ${ device.sm } {
      padding: 0;
    }
  }

  @media ${ device.hover } {
    &:hover {
      ${ RoomImage } {
        img {
          transform: scale(1.1);
        }
      }

      ${ RoomImageGradient } {
        opacity: 0.6;
      }
    }
  }

`

const Capacity = styled.div`
  margin-top: 1rem;
`

const CalendarContainer = styled(Row)`
  margin-top: 9.375rem;
`

const CalendarHeader = styled.h2`
  margin-bottom: 2rem;
`

const VermietungPage = ({
  location,
  data: {
    datoCmsRental: {
      header,
      description,
      rooms,
      referencesHeader,
      references,
      referenceLogosDesktop,
      referenceLogosMobile
    }
  }
}) => {
  const calendarRef = useRef()
  const calendarLimit = useCalendarLimit()

  const onAvailabiltyClick = () => {
    scrollToComponent(calendarRef.current, {
      align: 'top',
      duration: 1250,
      offset: -50,
      ease: 'inOutCube'
    })
  }

  return (
    <>
      <HouseHeader
        header={header}
        description={description}
        onAvailabiltyClick={onAvailabiltyClick}
        location={location}
      />
      <Background openTop>
        <Container>
          <Row>
            <Col xs={12} sm={2}>
              <h4>Räume</h4>
            </Col>
            <Col xs={12} sm={8}>
              <RoomsContainer>
                {rooms.map((room, index) => (
                  <RoomLink key={index} to={`/vermietung/${ slugify(room.linkTo.internalId) }`}>
                    <RoomImageWrapper>
                      <RoomImage fluid={room.image.fluid}/>
                      <RoomImageGradient name={slugify(room.linkTo.internalId)}/>
                    </RoomImageWrapper>
                    <Capacity>{room.capacity}</Capacity>
                    <h2>{room.name}</h2>
                  </RoomLink>
                ))}
              </RoomsContainer>
            </Col>
          </Row>
          <CalendarContainer ref={calendarRef}>
            <Col xs={12} sm={5} smOffset={1} lg={4} lgOffset={2}>
              <CalendarHeader>Verfügbarkeit prüfen</CalendarHeader>
              <div>
                Bevor Du uns eine Mietanfrage schickst, überprüfe bitte im Kalender, ob dein Wunschraum an deinem Wunschtermin noch verfügbar ist.
                An Sonntagen ist es nicht möglich, Bereiche anzumieten.
              </div>
            </Col>
            <Col xs={12} sm={5} lg={4}>
              <Calendar calendarLimit={calendarLimit}/>
            </Col>
          </CalendarContainer>
        </Container>
      </Background>
      <References
        referencesHeader={referencesHeader}
        references={references}
        referenceLogosDesktop={referenceLogosDesktop}
        referenceLogosMobile={referenceLogosMobile}
      />
      <Social/>
      <form
        name="Mietanfrage"
        data-netlify="true"
        netlify="true"
        data-netlify-honeypot="bot-field"
        netlify-honeypot="bot-field"
        hidden
      >
        <input type="text" name="Name"/>
        <input type="text" name="Firma"/>
        <input type="email" name="Email"/>
        <input type="text" name="Telefon"/>
        <input type="text" name="Anzahl"/>
        <input type="text" name="Datum"/>
        <input type="text" name="Raum"/>
        <input type="text" name="Anlass"/>
        <input type="text" name="Budget"/>
        <input type="text" name="Details"/>
      </form>
    </>
  )
}

export const query = graphql`
  query VermietungPage {
    datoCmsRental {
      header
      description
      referencesHeader
      references {
        quotation
      }
      rooms {
        linkTo {
          internalId
        }
        name
        capacity
        image {
          fluid(maxWidth: 500, imgixParams: {
            w: "500",
            h: "500",
            fm: "jpg",
            auto: "compress,format",
            fit: "crop",
            crop: "edges",
          }) {
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
      }
      referenceLogosDesktop {
        fluid(maxWidth: 900, imgixParams: {
          fm: "jpg",
          auto: "compress,format",
          fit: "crop",
          crop: "edges"
        }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      referenceLogosMobile {
        fluid(maxWidth: 900, imgixParams: {
          fm: "jpg",
          auto: "compress,format",
          fit: "crop",
          crop: "edges",
          sat: -100
        }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
    }
  }
`

export default VermietungPage
