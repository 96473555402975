import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { colors, device } from '../styles/config'
import ArrowLeftSvg from '../images/arrow-left.svg'
import ArrowRightSvg from '../images/arrow-right.svg'

import useCarouselState from '../hooks/useCarouselState'

const Container = styled.div`
  width: 100%;
  ${ props => !props.autosize && css`
    height: 0;
    padding-bottom: 65%;
    position: relative;
  ` }
`

const InnerContainer = styled.div`
  overflow: hidden;
  ${ props => !props.autosize && css`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  ` }
`

const SlideContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  transition: transform  ${ props => props.opacityTransition ? '0.6s' : '0.3s' } ease-in-out;
  transform: ${ props => `translate3d(${ props.slide * (-100) }%,0,0)` };
`

const Slide = styled.div`
  min-width: 100%;
  
  ${ props => props.opacityTransition && css`
    transition: opacity ${ props => props.active ? '0.3s 0.3s' : '0.3s' } ease-in-out;
    opacity: ${ props => props.active ? 1 : 0 };

    @media ${ device.sm } {
      transition: initial;
      opacity: initial;

      h1 {
        transition: opacity ${ props => props.active ? '0.3s 0.3s' : '0.3s' } ease-in-out;
        opacity: ${ props => props.active ? 1 : 0 };
      }
    }
  ` }
`

const ControlsContainer = styled.div`
  position: absolute;
  bottom: 0;
  transform: translate3d(-105%, 0, 0);
  display: flex;
  justify-content: center;
  width: 140px;

  @media ${ device.lg } {
    transform: translate3d(0,105%,0);
  }
`

const Previous = styled.div`
  height: 50px;
  width: 50px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  svg {
    transform: translate3d(0,0,0);
    transition: transform 0.3s;

    path {
      fill: ${ colors.secondary };
    }
  }

  @media ${ device.hover } {
    &:hover {
      svg {
        transform: translate3d(-10px,0,0);
      }
    }
  }
`

const Next = styled(Previous)`
  margin-right: 0;
  justify-content: flex-end;

  @media ${ device.hover } {
    &:hover {
      svg {
        transform: translate3d(10px,0,0);
      }
    }
  }
`

const Carousel = ({ className, children, currentSlide, withControls, autosize, opacityTransition }) => {
  const { slide, next, previous } = (withControls && useCarouselState(children.length)) || {}

  return (
    <>
      <Container autosize={autosize} className={className}>
        <InnerContainer autosize={autosize}>
          <SlideContainer
            slide={withControls ? slide : currentSlide}
            opacityTransition={opacityTransition}
          >
            {children.map((child, index) => (
              <Slide
                key={index}
                active={index === currentSlide}
                opacityTransition={opacityTransition}
              >
                {child}
              </Slide>
            ))}
          </SlideContainer>
        </InnerContainer>
        {withControls &&
          <ControlsContainer>
            <Previous onClick={previous}><ArrowLeftSvg/></Previous>
            <Next onClick={next}><ArrowRightSvg/></Next>
          </ControlsContainer>
        }
      </Container>
    </>
  )
}
Carousel.propTypes = {
  // children: PropTypes.node.isRequired,
  // light: PropTypes.bool,
  // to: PropTypes.string,
}

export default Carousel
