import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from 'styled-components'
import GatsbyImage from 'gatsby-image'
import { Col, Row } from 'react-styled-flexboxgrid'
import Carousel from './Carousel'
import useCarouselState from '../hooks/useCarouselState'
import { colors, device } from '../styles/config'

const Container = styled.div`
  ${ props => props.theme.wrapper }
  margin-top: 3rem;

  h4 {
    &:before {
      display: block;
      content: '';
      margin-bottom: 20px;
      width: 55px;
      height: 5px;
      background-color: ${ colors.highlight };

      @media ${ device.sm } {
        margin-bottom: 10px;
      }
    }

    @media ${ device.sm } {
      margin-bottom: 3.125rem;
    }
  }
`

const QuotationContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: -1.5%;

  div {
    text-align: center;
  }
  
  p {
    margin-bottom: 1rem;
  }
`

const Reference = styled.div`

`

const LogoContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 3rem;
`

const Logos = styled(GatsbyImage)`
  /* width: 10rem; */
  /* margin-left: 1.5rem;
  margin-right: 1.5rem; */
`

const Controls = styled.div`
  margin-top: 3rem;
  margin-bottom: 3rem;

  > div {
    display: flex;
    justify-content: center;
  }
`

const ControlsButton = styled.div`
  height: 25px;
  width: 1.875rem;
  margin-right: 0.625rem;
  cursor: pointer;
  position: relative;

  &:after {
    display: block;
    content: '';
    width: 100%;
    height: 3px;
    top: 11px;
    position: absolute;
    background-color: #323D74;
    opacity: ${ props => props.selected ? '0.6' : '0.1' };
    transition: opacity 0.3s;
  }

  &:last-child {
    margin-right: 0;
  }
`
const LeftHeader = styled(Col)`
  padding-right: 0;
`

const Refernces = ({ referencesHeader, references, referenceLogosDesktop, referenceLogosMobile }) => {
  const { slide, next, setSlide } = useCarouselState(references.length)
  let timer

  useEffect(() => {
    timer = setInterval(next, 15000)

    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <>
      <Container>
        <Row>
          <LeftHeader xs={12} sm={2}>
            <h4>Referenzen</h4>
          </LeftHeader>
          <Col xs={12} sm={8}>
            <QuotationContainer>
              <h1>{referencesHeader}</h1>
              <Carousel currentSlide={slide} autosize>
                {references.map((reference, index) => (
                  <Reference key={index} dangerouslySetInnerHTML={{ __html: reference.quotation }}/>
                ))}
              </Carousel>
              <Controls>
                <div>
                  {references.map((reference, index) => (
                    <ControlsButton key={index} selected={slide === index} onClick={() => setSlide(index)}/>
                  ))}
                </div>
              </Controls>
            </QuotationContainer>
          </Col>
        </Row>
        <Row>
          <Col xs={12} smOffset={1} sm={10}>
            <Logos fluid={referenceLogosDesktop.fluid}/>
          </Col>
        </Row>
      </Container>

    </>
  )
}

Refernces.propTypes = {
  // children: PropTypes.node.isRequired,
  // light: PropTypes.bool,
  // to: PropTypes.string,
}

export default Refernces
